.project-box {
  max-width: 50vw;
  padding-bottom: var(--padding-l);
  margin-bottom: var(--margin-xl);
  position: relative;
  border-radius: var(--border-radius-m);
  background-color: var(--gray_light);
  box-shadow: var(--shadow-fth);
  transform: translateX(-100px);
  opacity: 0;
}

.project-box_image img,
.project-box_image input[type="checkbox"] {
  border-bottom: 0;
  margin: var(--margin-l) var(--margin-m) 0 var(--margin-l);
  width: 100%;
  height: 100%;
  max-width: 50vw;
  max-height: 25vh;
  object-fit: cover;
  object-position: top;
  border-radius: var(--border-radius-m);
  border: 1px solid var(--black_light);
  box-shadow: var(--shadow-snd);
  transition: margin 0.2s linear, box-shadow 0.2s linear, max-width 0.2s linear,
    max-height 0.2s linear, width 0.2s linear, height 0.2s linear;
}

.project-box_image input[type="checkbox"] {
  opacity: 0;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.project-box_image input[type="checkbox"]:checked ~ img,
.project-box_image input[type="checkbox"]:checked {
  margin-top: 0;
  margin-left: 0;
  box-shadow: none;
  border-left: 1px solid var(--black_light);
  border-right: 1px solid var(--black_light);
  max-height: 100%;
  max-width: 100%;
}

.project-box_info {
  padding: var(--padding-l) var(--padding-l) 0 var(--padding-l);
}

.project-box_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--black_light);
  padding-bottom: var(--padding-s);
}

.project-box_header a {
  padding: var(--padding-m) var(--padding-m);
  border-radius: var(--border-radius-s);
  background-color: var(--gray_light);
  transition: background-color 0.2s linear, box-shadow 0.2s linear;
}

.project-box_header a:hover {
  box-shadow: var(--shadow-fth);
}

.project-box_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-box_btn {
  margin: 0 var(--margin-s);
}

.project-box_icon {
  color: var(--black);
}

.project-box_description {
  text-align: justify;
  color: var(--black);
  padding-top: var(--padding-l);
  padding-bottom: var(--padding-l);
  overflow: hidden;
  white-space: pre-line;
  font-size: var(--font-size-m);
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .project-box {
    max-width: 100%;
    transform: translateX(0);
  }

  .project-box_image img,
  .project-box_image input[type="checkbox"] {
    max-width: 100%;
  }
}
