*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  --black_light: rgba(0, 0, 0, 0.1);
  --black: #18191f;
  --white_light: rgba(255, 255, 255, 0.1);
  --white: #fff;
  --gray_light: #f5f5f5;
  --gray: #828282;
  --gray_dark: #4c4d50;

  --border-radius-s: 0.2vw;
  --border-radius-m: 0.5vw;
  --border-radius-xl: 1vw;

  --padding-s: 0.3vmax;
  --padding-m: 0.6vmax;
  --padding-l: 1.3vmax;
  --padding-xl: 2.5vmax;
  --padding-xll: 6.2vmax;

  --margin-s: 0.3vmax;
  --margin-m: 0.6vmax;
  --margin-l: 1.3vmax;
  --margin-xl: 2.5vmax;

  --font-weight-s: 300;
  --font-weight-m: 500;
  --font-weight-xl: 600;

  --font-size-s: 0.8rem;
  --font-size-m: 1rem;
  --font-size-l: 1.2rem;
  --font-size-xl: 2.3rem;
  --font-size-xll: 3.2rem;

  --drop_shadow: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));

  --shadow-prm: -1px -1px 4px rgba(255, 255, 255, 0.05),
    4px 4px 6px rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px rgba(255, 255, 255, 0.05),
    inset 1px 1px 1px rgba(0, 0, 0, 0.1);

  --shadow-snd: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

  --shadow-thd: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  --shadow-fth: rgba(0, 0, 0, 0.18) 0px 2px 4px;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--white);
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: var(--black);
  font-weight: var(--font-weight-xl);
  font-size: var(--font-size-xl);
  padding-bottom: var(--padding-s);
}

h2 {
  color: var(--black);
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
}

@media (max-width: 768px) {
  body {
    --font-size-xl: 5vw;
    --font-size-xll: 5.7vw;
  }
}
