.academic-box {
  display: flex;
  position: relative;
  justify-content: center;
}

.academic-box_info {
  position: relative;
  padding: var(--padding-l);
  min-height: 100px;
  padding-bottom: 0;
  width: 100%;
  border-left: 5px solid var(--black);
}

.academic-box_date {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-s);
  color: var(--black);
  align-items: center;
  margin: auto;
  padding-right: var(--padding-xl);
}

.academic-box_point {
  width: 21px;
  height: 21px;
  background-color: var(--black);
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 20px / 2);
  left: -13px;
}

.academic-box_info p {
  color: var(--black);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-s);
  padding-top: var(--padding-s);
}

.academic-box_info p a {
  color: var(--black);
  text-decoration: none;
}

.academic-box_info p a:hover {
  color: var(--black);
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}

.academic-box_info .emoji {
  font-size: var(--font-size-l);
  padding-right: var(--padding-s);
}

.academic-box_info .date {
  color: var(--gray_dark);
}

.academic-box_description {
  color: var(--black);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-s);
  padding: var(--padding-m) 0;

  text-align: justify;
  overflow: hidden;
  white-space: pre-line;
}

.academic-box_description_show {
  margin-bottom: var(--margin-m);
  padding-top: var(--padding-l);
}

.academic-box_description .title {
  text-decoration: underline;
}
.academic-box_description .description {
  padding-top: var(--padding-s);
  padding-left: var(--padding-l);
}

@media (max-width: 760px) {
  .academic-box_info {
    padding: 0 0 var(--padding-xl) var(--padding-xl);
  }

  .academic-box_date {
    display: none;
  }

  .academic-box_info p {
    padding-top: var(--padding-l);
  }

  .academic-box_info .emoji {
    font-size: var(--font-size-m);
  }
}
