.glow-button {
  background-color: var(--black);
  font-size: var(--font-size-xl);
  border-radius: var(--border-radius-m);
  box-shadow: var(--shadow-prm);
  color: var(--gray);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-left: var(--margin-m);
  margin-right: var(--margin-m);

  transition: color 0.5s ease-out;
  user-select: none;
}

.glow-button a:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  height: 50%;
  width: calc(100% - 5px);
  border-top-left-radius: var(--border-radius-m);
  border-top-right-radius: var(--border-radius-m);
  background-color: var(--white_light);
}

.glow-button a {
  display: flex;
  padding: var(--margin-s) var(--margin-l);
  color: var(--gray);
}

.glow-button:hover a {
  color: var(--white);
  text-shadow: 0 0 10px var(--white), 0 0 20px var(--white);
  transition: text-shadow 0.5s cubic-bezier(0.04, 0.38, 0.28, 0.15);
}

.glow-button a:visited {
  color: var(--gray);
}

.glow-button a:hover {
  color: var(--white);
}

@media (max-width: 768px) {
  .glow-button {
    box-shadow: none;
    padding: var(--padding-l) var(--padding-xl);
  }

  .glow-button a:before {
    all: unset;
  }
}

@media (max-width: 425px) {
  .glow-button {
    padding: 0;
  }
}
