.gen-tools {
  display: flex;
  overflow: hidden;
}

.gen-tools span {
  font-size: var(--font-size-m);
  margin-right: var(--margin-m);
  border-bottom: 1px solid var(--gray);

  color: var(--black);
  font-weight: var(--font-weight-s);
}

.gen-tools ul {
  display: flex;
}

.gen-tools li {
  font-weight: var(--font-weight-s);
  list-style: none;
  padding-left: var(--padding-m);
  padding-right: var(--padding-m);
  color: var(--black);
  padding-left: var(--padding-s);
  padding-right: var(--padding-s);
  font-size: var(--font-size-m);
  vertical-align: baseline;
}

@media (max-width: 480px) {
  .gen-tools {
    flex-direction: column;
  }

  .gen-tools span {
    margin-bottom: var(--margin-m);
  }

  .gen-tools ul {
    flex-wrap: wrap;
  }

  .gen-tools li {
    margin-bottom: var(--margin-m);
  }
}
