.glow-checkbox-gen {
  display: flex;
  margin: var(--margin-m);
  animation: ease-in-out 1s reveal;
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
