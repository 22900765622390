.academic-wrapper {
  filter: var(--drop_shadow);
}

.academic-wrapper .article {
  background-color: var(--gray_light);
  clip-path: polygon(0 0, 100% 0, 100% 78%, 75% 100%, 11% 100%, 0 90%);
}

.article h1 {
  margin-top: var(--margin-l);
  margin-bottom: var(--margin-l);
}

.academics-grid {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  gap: 0px;
}


@media (max-width: 760px) {
  .academic-wrapper .article {
    clip-path: polygon(0 0, 100% 0, 100% 99%, 95% 100%, 2% 97%, 0 0%);
  }
}